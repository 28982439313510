<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    extends: idPageInit,
    setup() {
        useHead({
            title: 'Pildom | Profil',
            meta: [
                { name: 'description', content: 'Gestion du profil' },
            ],
        })
        definePageMeta({
            middleware: ['auth'],
        })
    },
    data: () => {
        return {
            requestName: 'my-profile',
            translationName : 'profil',
        }
    },
    computed: {
        currentKey() {
            return ''
        },
    },
    methods : {
        refreshTable ()  {
            if (this.$refs.display.pageRef?.$refs.profil?.$refs.profilForm?.$refs.modalParent)
                this.$refs.display.pageRef?.$refs.profil?.$refs.profilForm?.$refs.modalParent.showModal()

            this.getFullData();
            this.saving = false; 
            
        },
        cancelSave () {
            this.saving = false; 
            this.getFullData();
        }
    }

}
</script>

<template>
    <NuxtPage
        v-if="fetchedData"
        ref="display"
        :saving
        :loading
        :translation-name
        :data="fetchedData"
        :rules="rules"
        @save="save()"
        @close-modal="cancelSave()"
    />
</template>
